<template>  
  <div class="container-fluid">
    <v-card class="ma-2">           
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3"><v-icon class="mr-2">mdi-checkbox-marked</v-icon>API Log</v-toolbar-title>        
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn  
          color="blue"
          text               
          @click="initialize()"
          class="font-weight-bold text-subtitle-1"
        ><v-icon big class="mr-2">refresh</v-icon>Refresh</v-btn>         
        <v-btn color="blue" text @click="ExcelExpert('Log')" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>Excel
        </v-btn>
        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="blue accent-4"
        ></v-progress-linear>          
      </v-toolbar>        
      <v-divider></v-divider>       
      <v-card tile flat class="d-flex ml-3 mr-3">                
        <v-card class="ml-2 mb-1" flat>             
          <IronDate ref="IronDateRef" v-model="sNowSelectDate" @update="onDateRangeChange"/>
        </v-card>       
        
        <div style="width:100px;" class="ml-3">       
          <v-select v-model="sGroup" class="mb-n5" label="Group" :items="zGroup" item-text="sNm" item-value="sId"></v-select>                     
        </div>

        <div class="d-flex" style="width:700px;">
          <v-text-field
            v-model="cNowSearchKeyWord2"
            class="ml-3 mt-1 mb-n6"  
            label="Event"
            placeholder="Please enter a event"
            persistent-placeholder
            style="max-width:200px;"
            Regular
            v-on:keyup.enter="Search()"
          ></v-text-field>
          <v-text-field
            v-model="cNowSearchKeyWord"
            class="ml-3 mt-1 mb-n6"  
            label="Search"
            placeholder="Please enter a keyword"
            persistent-placeholder
            style="max-width:300px;"
            Regular
            v-on:keyup.enter="Search()"
          ></v-text-field>
          <v-btn  text icon  
          class="ml-n9 mt-2"                
            @click="Search()"            
          ><v-icon>search</v-icon>
          </v-btn>      
        </div>
      </v-card>
                
      <v-divider></v-divider>
      <div class="table_div"></div>

      <v-data-table :items-per-page="15" fixed-header :headers="headers"
        :items="Records"             
        height="calc(100vh - 248px)"> 
        <template v-slot:item.cType="{ item }">        
          <v-chip small label :color="getColor(item.cType)" dark >{{ item.cType }}</v-chip>        
        </template>
        <template v-slot:item.cGroup="{ item }">        
          <v-chip v-if="item.cGroup == 'U'" small label color="green" dark >Manager</v-chip>        
          <v-chip v-if="item.cGroup == 'M'" small label color="grey-lienght-4" >Member</v-chip>        
        </template>
        <template v-slot:item.cResult="{ item }">        
          <v-chip v-if="item.cResult != ''" small label :color="getColor2(item.cResult)" dark >{{ item.cResult }}</v-chip>        
        </template>
        <template v-slot:item.cMemberNick="{ item }">        
          <div v-if="item.cMemberNick != 'NONE'" >{{ item.cMemberNick }}</div>        
        </template>
        <template v-slot:item.cUserNm="{ item }">        
          <div v-if="item.cUserNm != 'NONE'" >{{ item.cUserNm}}</div>        
        </template>

        <template v-slot:item.cData="{ item }">
          <div  style="max-width:500px">{{ item.cData }}</div>        
        </template>
        <template v-slot:headers="props">
          <tr> <th v-for="h in props.headers" :key="h.text" class="body-2 font-weight-bold"> {{ h.text }} </th> </tr> 
        </template>
      </v-data-table>       
    </v-card>  
  </div>
</template>

<script>
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios"
import {mapState} from "vuex";
import IronDate from '../components/iron28Calendar/src/IronDate.vue';
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';

export default {
  components: {        
    IronDate,
  },
  data: () => ({      
    sGroup : "ALL",
    zGroup : [{sId:"ALL", sNm:"ALL"}, {sId:"M", sNm:"Member"}, {sId:"U", sNm:"Manager"},],
    loading: false,    
    cNowSearchKeyWord : "",
    cNowSearchKeyWord2 : "",
    sNowSelectDate :  Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date()),
    sStartDate : Util.sFormatDate(Date()),
    sEndDate :Util.sFormatDate(Date()),
    headers: [            
      { class: "font-weight-bold subtitle-2", text: "No", value: "iEventLog", } ,
      { class: "font-weight-bold subtitle-2", text: "Datetime", value: "cDateTime", width:"170", align: "center"} ,      
      { class: "font-weight-bold subtitle-2", text: "Event", value: "cEvent", } ,
      { class: "font-weight-bold subtitle-2", text: "Group", value: "cGroup", align: "center"} ,      
      { class: "font-weight-bold subtitle-2", text: "Type", value: "cType", align: "center"} ,      
      { class: "font-weight-bold subtitle-2", text: "Member", value: "cMemberNick",},
      { class: "font-weight-bold subtitle-2", text: "Manager", value: "cUserNm",},
      { class: "font-weight-bold subtitle-2", text: "Result", value: "cResult"},
      { class: "font-weight-bold subtitle-2", text: "Data", value: "cData"},      
      
    ],
    Records: [],
  }),

  computed: {
    ...mapState(["ProgramPermissionInfo", "UserInfo"]),   
  },

  watch: {},

  mounted() {    
    this.initialize();       
  },

  methods: {    
    
    initialize() {          
      this.Search();
    },
    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;                 
    },
    
    getFontColor (cType, cCheck) {      
      if (cCheck == "Y"){        
        return 'gray--text font-weight-light'
      } else {
        if (cType == "Error") return 'red--text font-weight-regular'
        else if (cType == "Log") return 'teal--text text--accent-5 font-weight-regular'
        else if (cType == "Info") return 'blue--text font-weight-regular'
        else return 'black--text font-weight-regular'
      }
    },    
    
    getColor(d) {
      if (d === "OK") {
        return "#70bbfe"
      } else if (d === "Error") {
        return "#ff4862"
      } else if (d === "Warn") {
        return "#f6da6d"        
      } else if (d === "Disconnect") {
        return "#F44336"        
      } else if (d === "ON") {
        return "#70bbfe"
      } else if (d === "Log") {
        return "#4ce1b7"
      } else if (d === "Info") {
        return "#70bbfe"
      } else if (d === "REV") {
        return "#febb70"
      } else if (d === "SND") {
        return "#70bbfe"
      } else if (d === "OFF") {
        return "#ff4862"
      } else if (d === "") {
        return "#FFFFFF"
      } else {
        return "#444444"
      }
    },

    getColor2(d) {
      if (d === "") {
        return "#70bbfe"
      } else if (d === "00") {
        return "#eeeeee"
      } else  {
        return "#ee4862"
      }
    },
    
    
    getAllmsg(FullMsg ) {      
      var sRet = "";

      if (FullMsg === true) {
        return sRet;
      } else {
        return sRet + " " + "col-2 text-truncate";        
      }
    },

    Search(){
      this.Records = [];

      this.loading = true;                            
      var posData = {        
        cUserId : this.$store.state.UserInfo.UserId,             
        cStartDatetime: this.sStartDate,  
        cEndDatetime  : this.sEndDate,                        
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        cEvent: this.cNowSearchKeyWord2,
        cGroup : this.sGroup,
      }
      axios
        .post(BasicInfo.UIL_API + "GetEventLog", posData, {
            headers: {
            "Authorization": this.$store.state.UserInfo.Token                        
            }
          })
        .then(res => {                    
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;                
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;                      
        }).catch(err =>{
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;                      
        })         
    },

    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name+ '-'+Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },    
  }
};
</script>


